<template>
    <date-picker />
</template>

<script>
import DatePicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

export default {
    name: 'dn-date-picker',
    components: { DatePicker },
    setup() {},
}
</script>
